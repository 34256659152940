import { Injectable, inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class LoginGuard {
	private _router = inject(Router);
	private _authService = inject(AuthService);


	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (this._authService.isLoggedIn()) {
			return !this._router.navigate([this._authService.redirectUrl]);
		} else return true;
	}
}
