import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { concat, reduce } from 'lodash';
import { from, of, Subscription } from 'rxjs';

import { map, mergeMap, tap } from 'rxjs/operators';
import { SettingService } from '../../setting/setting.service';
import { UploadInstance } from '../../model';
import { UPLOAD_ITEM_ANIMATION } from '../../animations';
import { StringUtils } from '../../utils';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { NgClass } from '@angular/common';
import { ngfModule } from 'angular-file';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'ft-excel-exam',
    templateUrl: './excel-exam.component.html',
    styleUrls: ['./excel-exam.component.scss'],
    animations: [UPLOAD_ITEM_ANIMATION],
    imports: [
        MatIconButton,
        MatIcon,
        ngfModule,
        NgClass,
        MatProgressBar,
        TranslateModule,
    ]
})
export class ExcelExamComponent {
	public total: number = 0;
	public files: File[] = [];
	public currentFile: UploadInstance;

	public hasBaseDropZoneOver = false;
	public subscription: Subscription | Promise<any>;
	public fileTypesAuthorized =
		'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
	public uploadComplete: boolean = false;

	private service = inject(SettingService);
	private cdr = inject(ChangeDetectorRef);
	private _bsRef = inject(MatBottomSheetRef);

	private static _handleEntries(event: any) {
		return Array.from(event.dataTransfer.items)
			.filter((item: DataTransferItem) => item.kind === 'file')
			.map((item: DataTransferItem) => item.webkitGetAsEntry());
	}

	fileSize(size: number): string {
		return StringUtils.humanFileSize(size);
	}

	public handleItems(event: any, isFiles: boolean) {
		const entries = isFiles
			? event
			: ExcelExamComponent._handleEntries(event);
		const subject = isFiles
			? of(event).pipe(
					map(items =>
						items.map((item: any) => {
							return { file: item, path: '/' + item.name };
						})
					)
				)
			: from(this._buildTree(entries)).pipe(
					map(items => this._inlineTree(items))
				);

		this.subscription = subject
			.pipe(
				tap(items => (this.total = items.length)),
				map(items =>
					items.map(
						(item: any, index: number) =>
							new UploadInstance(item.file, index, item.path)
					)
				),
				mergeMap(items => this.service.uploadFiles(items))
			)
			.subscribe({
				next: data => {
					this.currentFile = data;
					this.cdr.detectChanges();
				},
				complete: () => {
					this.files = [];
					this.currentFile = null;
					this.uploadComplete = true;

					this.service.createTemplates().subscribe();
				},
			});
	}

	private _inlineTree(tree: any) {
		return reduce(
			tree.directories,
			(files: any, directory: any) => {
				return concat(files, this._inlineTree(directory));
			},
			tree.files
		);
	}

	close(res: any) {
		this._bsRef.dismiss(res);
		if (this.subscription instanceof Subscription)
			this.subscription.unsubscribe();
	}

	private _parseDirectoryEntry(directoryEntry: any) {
		const directoryReader = directoryEntry.createReader();
		return new Promise((resolve, reject) => {
			directoryReader.readEntries(
				(entries: any) => resolve(this._buildTree(entries)),
				(err: any) => reject(err)
			);
		});
	}

	private async _buildTree(entries: any) {
		const promises = [];
		const tree = { files: [], directories: [] };
		entries.forEach((entry: any) => {
			if (entry.isFile) {
				const promise = this._parseFileEntry(entry).then(file => {
					tree.files.push(file);
				});
				promises.push(promise);
			} else if (entry.isDirectory) {
				const promise = this._parseDirectoryEntry(entry).then(
					directory => {
						tree.directories.push(directory);
					}
				);

				promises.push(promise);
			}
		});

		await Promise.all(promises);
		return tree;
	}

	private _parseFileEntry(fileEntry: any) {
		return new Promise((resolve, reject) => {
			fileEntry.file(
				(file: any) => resolve({ file, path: fileEntry.fullPath }),
				(err: any) => reject(err)
			);
		});
	}
}
