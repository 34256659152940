import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogActions } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-print-count',
    templateUrl: './print-count.component.html',
    styleUrls: ['./print-count.component.scss'],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        MatFormField,
        MatLabel,
        MatInput,
        MatDialogActions,
        TranslateModule,
    ]
})
export class PrintCountComponent {
	count = inject(MAT_DIALOG_DATA);
}
