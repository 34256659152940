import { AfterViewInit, Component, ElementRef, OnInit, inject, viewChild } from '@angular/core';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import {
	BehaviorSubject,
	forkJoin,
	fromEvent as observableFromEvent,
	merge,
	of as observableOf,
} from 'rxjs';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SharedService } from '../shared.service';
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import { rowsAnimation } from '../../animations';
import { REF_PH_HEADER_COLS, REF_PH_TABLE_CONF } from './table-conf';
import { MatDialogRef, MatDialogClose, MatDialogActions } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingService } from '../../setting/setting.service';
import { assign } from 'lodash';
import { ReferringPhysicianDTO } from '../../model';
import { TranslateModule } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';
import { NgStyle, DatePipe } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatPrefix, MatLabel } from '@angular/material/form-field';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-referring-physicians-search',
    templateUrl: './referring-physicians-search.component.html',
    styleUrls: ['./referring-physicians-search.component.scss'],
    animations: [rowsAnimation],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        MatFormField,
        MatPrefix,
        MatInput,
        MatTable,
        MatSort,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatSortHeader,
        MatCellDef,
        MatCell,
        NgStyle,
        MatTooltip,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatProgressSpinner,
        MatPaginator,
        FormsModule,
        ReactiveFormsModule,
        MatLabel,
        MatSelect,
        MatOption,
        MatButton,
        MatDialogActions,
        DatePipe,
        TranslateModule,
    ]
})
export class ReferringPhysiciansSearchComponent
	implements OnInit, AfterViewInit
{
	private shared = inject(SharedService);
	private _fb = inject(FormBuilder);
	private _setting = inject(SettingService);
	private _dialogRef = inject<MatDialogRef<ReferringPhysiciansSearchComponent>>(MatDialogRef);

	dataSource = new MatTableDataSource();
	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;

	filterChange = new BehaviorSubject('');

	cols: any[];
	displayedColumns = [];

	referringForm: FormGroup;

	titles: any[] = [];
	specialities: any[] = [];

	readonly filter = viewChild<ElementRef>('filter');
	readonly sort = viewChild(MatSort);
	readonly paginator = viewChild(MatPaginator);

	constructor() {
		this.cols = REF_PH_HEADER_COLS;
		this.displayedColumns = REF_PH_TABLE_CONF;

		this.createForm();
	}

	private createForm() {
		this.referringForm = this._fb.group(
			assign(new ReferringPhysicianDTO(), {
				firstName: ['', Validators.required],
				lastName: ['', Validators.required],
				functionId: 130,
			})
		);
	}

	ngOnInit() {
		this.filter().nativeElement.focus();
	}

	trackByLastName(index: number, item: any): string {
		return item.lastName;
	}

	selectPhysician(row) {
		this._dialogRef.close(row);
	}

	ngAfterViewInit() {
		observableFromEvent(this.filter().nativeElement, 'keyup')
			.pipe(debounceTime(400), distinctUntilChanged())
			.subscribe(() => {
				if (!this.dataSource) return;
				this.paginator().pageIndex = 0;
				this.filterChange.next(this.filter().nativeElement.value);
			});

		this.sort().sortChange.subscribe(() => (this.paginator().pageIndex = 0));

		merge(
			this.sort().sortChange.asObservable(),
			this.paginator().page.asObservable(),
			this.filterChange
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					return this.shared.queryReferringPhysicians(
						this.paginator().pageSize,
						this.paginator().pageIndex,
						this.sort().active,
						this.sort().direction,
						this.filter().nativeElement.value
					);
				}),
				map(data => {
					this.isLoadingResults = false;
					this.isRateLimitReached = false;
					this.resultsLength = data['totalElements'];
					const physicians = data['content'];
					return physicians.filter(val => val.id !== 420);
				}),
				catchError(() => {
					this.isLoadingResults = false;
					this.isRateLimitReached = true;
					return observableOf([]);
				})
			)
			.subscribe(data => (this.dataSource.data = data));
	}

	editReferringPhysician(row) {
		const sidenav = document.getElementById('sidenav');
		if (!sidenav.classList.contains('visible')) this.toggleSidenav();

		if (this.titles.length === 0)
			forkJoin([
				this.shared.getTitles(),
				this.shared.getSpecialities(),
			]).subscribe(data => {
				[this.titles, this.specialities] = data;

				this.referringForm.patchValue(row);
			});
		else this.referringForm.patchValue(row);
	}

	toggleSidenav() {
		document.getElementById('sidenav').classList.toggle('visible');
	}

	saveReferringPhysician(value: any) {
		this._setting.saveReferringPhysician(value).subscribe(_ => {
			this.toggleSidenav();
			this.filterChange.next('');
		});
	}
}
