import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { SharedService } from '../shared.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportingService } from '../../reporting/reporting.service';
import { Subscription } from 'rxjs';
import { DelegationType } from '../../model';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';

import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-performer-assign',
    templateUrl: './performer-assign.component.html',
    styleUrls: ['./performer-assign.component.scss'],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        
        MatDialogContent,
        MatFormField,
        MatLabel,
        MatSelect,
        FormsModule,
        MatOption,
        MatInput,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ]
})
export class PerformerAssignComponent implements OnInit, OnDestroy {
	private data = inject(MAT_DIALOG_DATA);
	private _shared = inject(SharedService);
	private _dialogRef = inject<MatDialogRef<PerformerAssignComponent>>(MatDialogRef);
	private _snack = inject(MatSnackBar);
	private _service = inject(ReportingService);

	radiologists: any[] = [];
	assignedRadiologist: any;
	private readonly reportingTaskId: number;
	comment: string = '';
	title: DelegationType = DelegationType.DELEGATE_TASK;
	private sub: Subscription;

	constructor() {
		this.title = this.data.title;
		this.sub = this._shared
			.getPerformingPhysicians()
			.subscribe(data => (this.radiologists = data));
		this.reportingTaskId = this.data.task.id;
	}

	saveRow() {
		if (this.title === DelegationType.DELEGATE_TASK) {
			this._service
				.delegateTask(
					this.reportingTaskId,
					this.assignedRadiologist?.id,
					this.comment
				)
				.subscribe(isOk => {
					if (isOk) {
						this._snack.open(
							`La tâche a été Déléguée à ${this.assignedRadiologist.fullName}`,
							'ok',
							{ duration: 2000 }
						);
						this._dialogRef.close(null);
					}
				});
		} else if (this.title === DelegationType.RE_ASSIGN_TASK) {
			this._service
				.reassignTask(
					this.reportingTaskId,
					this.assignedRadiologist?.id
				)
				.subscribe(isOk => {
					if (isOk) {
						this._snack.open(
							`La tâche a été affectée à ${this.assignedRadiologist.fullName}`,
							'ok',
							{ duration: 2000 }
						);
						this._dialogRef.close(null);
					}
				});
		}
	}

	compareFn(a: any, b: any): boolean {
		return a && b ? a.id === b.id : a === b;
	}

	ngOnInit() {
		this.assignedRadiologist = this.data.task.secondPerformerName;
		this.comment = this.data.task.assigningComment;
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}
}
