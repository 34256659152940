import { Component, OnDestroy, OnInit, inject, viewChild } from '@angular/core';
import { MatDialogRef, MatDialogClose, MatDialogActions } from '@angular/material/dialog';
import { get, isNil, noop } from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { NgStyle } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

declare let MediaRecorder: any;

@Component({
    selector: 'ft-audio-record',
    templateUrl: './audio-record.component.html',
    styleUrls: ['./audio-record.component.scss'],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        MatTooltip,
        NgStyle,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ]
})
export class AudioRecordComponent implements OnInit, OnDestroy {
	private dialogRef = inject<MatDialogRef<AudioRecordComponent>>(MatDialogRef);

	record = null;
	stream = null;
	audioFile = null;
	audioChunks: any[] = [];

	isPlay = false;
	isStop = true;
	hasNoFile = true;

	showPreview = false;
	err = true;

	audio = null;

	readonly myAudio = viewChild<any>('myAudio');

	ngOnInit() {
		this.audio = this.myAudio().nativeElement;

		const getUserMedia = get(
			window,
			'navigator.mediaDevices.getUserMedia',
			null
		);

		if (!isNil(getUserMedia)) {
			window.navigator['mediaDevices']['getUserMedia']({ audio: true })
				.then(
					stm => this.handleStream(stm),
					() => this.error()
				);
		}
	}

	handleStream(stm) {
		this.stream = stm;

		this.record = new MediaRecorder(this.stream);
		this.record.ondataavailable = e => this.onDataAvailable(e);

		if (!this.isPlay) this.startRecording();
	}

	onDataAvailable(e) {
		this.audioChunks.push(e.data);

		if (this.record.state === 'inactive') {
			this.hasNoFile = false;

			this.audioFile = new Blob(this.audioChunks, { type: 'audio/wav' });
			this.audio.src = window.URL.createObjectURL(this.audioFile);
		}
	}

	startRecording() {
		if (this.record.state === 'inactive') this.record.start();

		this.isPlay = true;
		this.isStop = false;
		this.showPreview = false;
	}

	pauseRecording() {
		if (this.record.state !== 'inactive') this.record.stop();
		this.isPlay = this.isStop = false;
	}

	stopRecording() {
		if (this.record.state !== 'inactive') this.record.stop();

		this.isPlay = false;
		this.isStop = true;
		this.showPreview = true;

		this.audioChunks = [];
	}

	error() {
		this.err = true;
	}

	saveFile() {
		this.dialogRef.close(this.audioFile);
	}

	ngOnDestroy() {
		isNil(this.stream)
			? noop()
			: this.stream['getAudioTracks']().forEach(function (track) {
					track.stop();
				});
	}
}
