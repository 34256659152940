import { Component } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';

@Component({
    selector: 'ft-form-radio',
    templateUrl: './form-radio.component.html',
    styleUrls: ['./form-radio.component.scss'],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatRadioGroup,
        MatRadioButton,
    ]
})
export class FormRadioComponent implements Field {
	config: any;
	group: FormGroup;
}
