import {
	AfterViewInit,
	Component, DestroyRef,
	inject,
	OnInit, viewChild,
} from '@angular/core';
import {
	MatTableDataSource,
	MatTable,
	MatColumnDef,
	MatHeaderCellDef,
	MatHeaderCell,
	MatCellDef,
	MatCell,
	MatHeaderRowDef,
	MatHeaderRow,
	MatRowDef,
	MatRow,
} from '@angular/material/table';
import {
	forkJoin,
	merge,
	of as observableOf,
} from 'rxjs';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SharedService } from '../shared.service';
import {
	catchError,
	debounceTime,
	distinctUntilChanged, filter,
	map,
	startWith,
	switchMap, tap,
} from 'rxjs/operators';
import { rowsAnimation } from '../../animations';
import { PATIENT_LIST_CONFIG } from './table-conf';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { PatientService } from '../../patient/patient.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatInput, MatSuffix } from '@angular/material/input';
import { MatFormField, MatPrefix } from '@angular/material/form-field';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';
import {
	MatDatepickerModule,
} from '@angular/material/datepicker';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import moment from 'moment';

@Component({
    selector: 'ft-patient-list',
    templateUrl: './patient-list.component.html',
    styleUrls: ['./patient-list.component.scss'],
    animations: [rowsAnimation],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        MatFormField,
        MatPrefix,
        MatInput,
        MatTable,
        MatSort,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatSortHeader,
        MatCellDef,
        MatCell,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatProgressSpinner,
        MatPaginator,
        DatePipe,
        TranslateModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatSuffix,
    ]
})
export class PatientListComponent implements OnInit, AfterViewInit {
	dataSource = new MatTableDataSource();
	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;

	cols: any[];
	displayedColumns = [];

	titles = {};
	genders = {};

	#destroyRef = inject(DestroyRef);

	sort = viewChild<MatSort>(MatSort);
	paginator = viewChild<MatPaginator>(MatPaginator);

	filterForm!: FormGroup;


	#fb = inject(FormBuilder);
	data = inject(MAT_DIALOG_DATA);
	#shared = inject(SharedService);
	#patientService = inject(PatientService);
	#dialogRef = inject(MatDialogRef<PatientListComponent>);

	constructor() {
		this.filterForm = this.#fb.group({ key: '', date: '' });
		this.cols = PATIENT_LIST_CONFIG.filter(it => !it.hidden).map(it => it.label);
		this.displayedColumns = PATIENT_LIST_CONFIG;
	}

	ngOnInit() {
		forkJoin([
			this.#shared.getTitles(),
			this.#shared.getGenders(),
		]).pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(data => {
			const [titles, genders] = data;
			titles.forEach(it => (this.titles[it.id] = it.value));
			genders.forEach(it => (this.genders[it.id] = it.value));
		});
	}

	ngAfterViewInit() {
		const { firstName, lastName } = this.data;
		const query = [firstName, lastName].join(' ').trim();
		this.filterForm.patchValue({ key: query });

		this.filterForm.valueChanges
			.pipe(
				debounceTime(400),
				distinctUntilChanged(),
				takeUntilDestroyed(this.#destroyRef),
			)
			.subscribe(() => {
				if (!this.dataSource) return;
				this.paginator().pageIndex = 0;
			});

		this.sort().sortChange.subscribe(() => (this.paginator().pageIndex = 0));

		merge(
			this.sort().sortChange.asObservable(),
			this.paginator().page.asObservable(),
			this.filterForm.valueChanges,
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;

					const paginator = this.paginator();
					const sort = this.sort();
					const { key, date } = this.filterForm.getRawValue();

					const _key = key || '';
					let _date = date || undefined;

					if (_date && _date.isValid() && moment().diff(moment(_date), 'years') <= 140) _date = _date?.format('YYYYMMDD');
					else _date = '';

					return this.#patientService.getPatientsV2(
						paginator.pageSize,
						paginator.pageIndex,
						sort.active,
						sort.direction,
						[_key, _date].join('__'),
					);
				}),
				map(data => {
					this.isLoadingResults = false;
					this.isRateLimitReached = false;
					this.resultsLength = data['totalElements'];
					return data['content'];
				}),
				catchError(() => {
					this.isLoadingResults = false;
					this.isRateLimitReached = true;
					return observableOf([]);
				}),
				takeUntilDestroyed(this.#destroyRef),
			)
			.subscribe(data => (this.dataSource.data = data));
	}

	selectPatient(row: any) {
		this.#dialogRef.close(row);
	}
}
