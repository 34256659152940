<div class="ft-row">
	<div class="ft-cell">{{ paymentItem.accessionNumber }}</div>
	<div class="ft-cell">{{ paymentItem.procedureCode }}</div>
	<div class="ft-cell">
		<input
			type="number"
			[disabled]="disabled()"
			(keyup)="onPriceChange($event)"
			[(ngModel)]="paymentItem.price"
			name="price" />
	</div>
	<div class="ft-cell">
		<input
			type="number"
			[disabled]="disabled()"
			(keyup)="onQuantityChange($event)"
			[(ngModel)]="paymentItem.quantity"
			name="quantity" />
	</div>
	<div class="ft-cell">
		<input
			type="number"
			[disabled]="disabled()"
			(keyup)="onDiscountChange($event)"
			[(ngModel)]="paymentItem.discount"
			name="discount" />
	</div>
	<div class="ft-cell">
		<input
			type="number"
			[disabled]="disabled()"
			(keyup)="onPaidAmountChange($event)"
			[(ngModel)]="paymentItem.paidAmount"
			name="paidAmount" />
	</div>
</div>
