import {
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButton, MatIconButton } from '@angular/material/button';

@Component({
    selector: 'ft-menu',
    templateUrl: './ft-menu.component.html',
    styleUrls: ['./ft-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [
        MatButton,
        MatTooltip,
        MatMenuTrigger,
        MatIconButton,
        MatIcon,
        MatMenu,
        MatMenuItem,
        TranslateModule,
    ]
})
export class FtMenuComponent {
	@Output() menuItemSelect = new EventEmitter();

	private _listItem: MenuItem[];
	private _menuType!: string;
	currentCalendar = 'RDV';

	// TODO: Skipped for migration because:
	//  Accessor inputs cannot be migrated as they are too complex.
	@Input()
	set listItem(listItem: MenuItem[]) {
		this._listItem = listItem;
	}

	get listItem(): MenuItem[] {
		return this._listItem;
	}

	// TODO: Skipped for migration because:
	//  Accessor inputs cannot be migrated as they are too complex.
	@Input()
	set menuType(menuType: string) {
		this._menuType = menuType;
	}

	get menuType(): string {
		return this._menuType;
	}

	constructor() {
		this.menuItemSelect.subscribe(value => {
			if (this.menuType === 'calendar') {
				this.currentCalendar = value;
			}
		});
	}
}

export interface MenuItem {
	icon: string;
	name: string;
	value: string;
}
