import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { PaymentItem } from '../../model';
import { set } from 'lodash';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'ft-table-line',
    templateUrl: './table-line.component.html',
    styleUrls: ['./table-line.component.scss'],
    imports: [FormsModule]
})
export class TableLineComponent {
	// TODO: Skipped for migration because:
	//  Your application code writes to the input. This prevents migration.
	@Input() paymentItem: PaymentItem;
	readonly disabled = input<boolean>(undefined);

	@Output() paymentChange = new EventEmitter();

	constructor() {}

	onDiscountChange(event: KeyboardEvent) {
		const discount = event.target['value'];
		const paidAmount =
			this.paymentItem.price * this.paymentItem.quantity - discount;
		const leftAmount =
			this.paymentItem.price * this.paymentItem.quantity -
			paidAmount -
			discount;

		if (discount) {
			set(this.paymentItem, 'paidAmount', paidAmount);
			set(this.paymentItem, 'leftAmount', leftAmount);
			this.paymentChange.emit(this.paymentItem);
		}
	}

	onPaidAmountChange(event: KeyboardEvent) {
		const paidAmount = event.target['value'];
		const leftAmount =
			this.paymentItem.price * this.paymentItem.quantity -
			paidAmount -
			this.paymentItem.discount;

		if (paidAmount) {
			set(this.paymentItem, 'leftAmount', leftAmount);
			this.paymentChange.emit(this.paymentItem);
		}
	}

	onQuantityChange(event: KeyboardEvent) {
		const quantity = event.target['value'];
		if (quantity) {
			const leftAmount =
				this.paymentItem.price * quantity -
				this.paymentItem.paidAmount -
				this.paymentItem.discount;
			set(this.paymentItem, 'leftAmount', leftAmount);
			this.paymentChange.emit(this.paymentItem);
		}
	}

	onPriceChange(event: KeyboardEvent) {
		const price = event.target['value'];
		const leftAmount =
			price * this.paymentItem.quantity -
			this.paymentItem.discount -
			this.paymentItem.paidAmount;

		if (price) {
			set(this.paymentItem, 'leftAmount', leftAmount);
			this.paymentChange.emit(this.paymentItem);
		}
	}
}
