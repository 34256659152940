import { Component } from '@angular/core';
import { Field } from '../../models/field.interface';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'ft-form-textarea',
    templateUrl: './form-textarea.component.html',
    styleUrls: ['./form-textarea.component.scss'],
    imports: [
        MatFormField,
        FormsModule,
        ReactiveFormsModule,
        MatLabel,
        MatInput,
    ]
})
export class FormTextareaComponent implements Field {
	config: any;
	group: FormGroup;

	constructor() {}
}
