import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-value-description',
    templateUrl: './value-description.component.html',
    styleUrls: ['./value-description.component.scss'],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ]
})
export class ValueDescriptionComponent implements OnInit {
	private fb = inject(FormBuilder);
	dialogRef = inject<MatDialogRef<ValueDescriptionComponent>>(MatDialogRef);

	selectedItem: any;
	form: FormGroup;

	constructor() {
		this.form = this.fb.group({
			id: '',
			value: [
				'',
				Validators.compose([
					Validators.required,
					Validators.maxLength(20),
				]),
			],
			description: [
				'',
				Validators.compose([
					Validators.required,
					Validators.maxLength(36),
				]),
			],
			deleted: false,
		});
	}

	onSave(data) {
		this.dialogRef.close(data);
	}

	ngOnInit() {
		this.form.patchValue(
			this.selectedItem || {
				id: 0,
				value: '',
				description: '',
				deleted: false,
			}
		);
	}
}
