import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, inject, viewChild } from '@angular/core';
import $ from 'jquery';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { FtSpeechRecognition } from '../../../ft-speech-recognition';
import { get } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { FileElement } from '../../model/file-element';
import { FileService } from '../../service/file.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMiniFabButton, MatButton } from '@angular/material/button';

@Component({
    selector: 'ft-speech-note',
    templateUrl: './speech-note.component.html',
    styleUrls: ['./speech-note.component.scss'],
    imports: [
        MatMiniFabButton,
        MatTooltip,
        MatIcon,
        MatButton,
        MatDialogClose,
        TranslateModule,
    ]
})
export class SpeechNoteComponent implements OnInit, OnDestroy, AfterViewInit {
	data = inject<FileElement>(MAT_DIALOG_DATA);
	private fileService = inject(FileService);
	private dialogRef = inject<MatDialogRef<SpeechNoteComponent>>(MatDialogRef);

	recognition: any;
	words;
	result;
	recording = new BehaviorSubject(false);
	readonly noteName = viewChild<ElementRef>('noteName');

	constructor() {
		this.recognition = new FtSpeechRecognition();
	}

	ngOnInit() {
		this.result = $('#result');
	}

	clearInput(e) {
		$('.placeholder').css('display', 'none');
	}

	startSpeak() {
		this.recording.next(true);
		this.recognition.start();
		this.recognition.subject.subscribe(results => {
			const final = get(results, 'results.0.isFinal');
			let transcript =
				get(results, 'results.0.0.transcript') + (final ? '' : ' ');

			transcript = transcript.replace('point', '. ');
			transcript = transcript.replace('virgule', ', ');
			transcript = transcript.replace('espace', ' ');

			if (final) this.result.text(this.result.text() + transcript);
		});
	}

	save() {
		this.recording.next(false);
		this.recognition.stop();
		this.dialogRef.close({
			noteName: this.noteName().nativeElement.value,
			note: this.result.text(),
		});
	}

	ngAfterViewInit(): void {
		if (this.data) {
			this.noteName().nativeElement.value = this.data.name;
			this.fetchText(`/api/fs/text/${this.data.uuid}`);
		}
	}

	fetchText(file) {
		const self = this;
		fetch(file, { mode: 'cors' })
			.then(function (response) {
				return response.text();
			})
			.then(function (text) {
				self.result.text(text);
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	ngOnDestroy() {
		this.recording.next(false);
		this.recognition.stop();
	}
}
