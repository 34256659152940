import { Component } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

import { MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-cancel-form',
    templateUrl: './cancel-form.component.html',
    styleUrls: ['./cancel-form.component.scss'],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        
        MatDialogContent,
        FormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        ReactiveFormsModule,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ]
})
export class CancelFormComponent {
	form = new FormControl<string>('', [Validators.required]);
	constructor() {}
}
