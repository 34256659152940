import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-delete-confirm',
    templateUrl: './delete-confirm.component.html',
    styleUrls: ['./delete-confirm.component.scss'],
    imports: [
        MatToolbar,
        MatIconButton,
        MatDialogClose,
        MatIcon,
        
        MatDialogContent,
        MatDialogActions,
        MatButton,
        TranslateModule
    ]
})
export class DeleteConfirmComponent {
	message = inject(MAT_DIALOG_DATA);
	private dialogRef = inject<MatDialogRef<DeleteConfirmComponent>>(MatDialogRef);

	selectedItem: any;

	onYes() {
		this.dialogRef.close(true);
	}

	onNo() {
		this.dialogRef.close(false);
	}
}
