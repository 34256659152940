import { Component, ElementRef, OnDestroy, OnInit, inject, viewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { FileElement } from '../../model/file-element';
import { assign, remove } from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import $ from 'jquery';
import { FileSystemService } from '../../../../statistic/file-system.service';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'ft-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss'],
    imports: [
        MatIconButton,
        MatIcon,
        MatDialogClose,
        NgClass,
    ]
})
export class ImageViewerComponent implements OnInit, OnDestroy {
	data = inject(MAT_DIALOG_DATA);
	private _fs = inject(FileSystemService);
	private sanitizer = inject(DomSanitizer);
	private dialogRef = inject<MatDialogRef<ImageViewerComponent>>(MatDialogRef);

	current: FileElement;
	images: FileElement[];

	readonly image = viewChild<ElementRef>('image');
	private kos: FileElement[] = [];

	constructor() {
		this.images = this.data.images.map(elm =>
			assign(elm, {
				url: this.data.fromPacs
					? elm.url
					: `/upload-dir/${elm.uuid}.${elm.fileType.toLowerCase()}`,
			})
		);
		this.current = assign(this.data.selected, {
			url: this.data.fromPacs
				? this.data.selected.url
				: `/upload-dir/${this.data.selected.uuid}.${this.data.selected.fileType.toLowerCase()}`,
		});

		document.addEventListener(
			'wheel',
			evt => {
				if (evt.deltaY < 0) this.next();
				else this.previous();
			},
			{
				capture: true,
				passive: true,
			}
		);
	}

	getOriginalImage(url: string): string {
		// if (url && this.data.fromPacs) {
		//     let split = url.split('/');
		//     split.pop();
		//     return split.join('/');
		// }
		return url;
	}

	ngOnInit() {
		this.navigateImages();
	}

	setKOS(file: FileElement) {
		file.fav = !file.fav;
		if (file.fav) {
			this.kos.push(file);
		} else {
			this.kos = remove(this.kos, val => val === file);
		}
	}

	public zoomin(): any {
		const myImg = this.image().nativeElement;
		const currWidth = myImg.clientWidth;
		if (currWidth === 2500) return false;
		else {
			myImg.style.width = currWidth + 100 + 'px';
		}
	}

	public zoomout(): any {
		const myImg = this.image().nativeElement;
		const currWidth = myImg.clientWidth;
		if (currWidth === 100) return false;
		else {
			myImg.style.width = currWidth - 100 + 'px';
		}
	}

	public previous() {
		const idx = this.images.indexOf(this.current);
		this.current = idx !== 0 ? this.images[idx - 1] : this.images[0];
	}

	public next() {
		const idx = this.images.indexOf(this.current);
		this.current =
			idx !== this.images.length - 1
				? this.images[idx + 1]
				: this.images[this.images.length - 1];
	}

	download() {
		const filename =
			this.current.uuid + '.' + this.current.fileType.toLowerCase();
		window.open(`/api/documents/download/${filename}`, '_blank');
	}

	private navigateImages() {
		$(document).keydown(eventObject => {
			switch (eventObject.key) {
				case 'ArrowLeft':
					this.previous();
					break;
				case 'ArrowRight':
					this.next();
					break;
				case 'Escape':
					this.dialogRef.close(null);
					break;
			}
		});
	}

	ngOnDestroy(): void {
		this.dialogRef.close(this.kos);
	}
}
