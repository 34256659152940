import { Directive, OnInit, ViewContainerRef, inject, input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormButtonComponent } from './form-button/form-button.component';
import { FormInputComponent } from './form-input/form-input.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { FormTextareaComponent } from './form-textarea/form-textarea.component';
import { FormRadioComponent } from './form-radio/form-radio.component';
import { FormMultiselectComponent } from './form-multiselect/form-multiselect.component';

const components = {
	BUTTON: FormButtonComponent,
	TEXTFIELD: FormInputComponent,
	SELECT: FormSelectComponent,
	TEXTAREA: FormTextareaComponent,
	RADIO: FormRadioComponent,
	MULTI: FormMultiselectComponent,
};

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[dynamicField]',
    standalone: true,
})
export class DynamicFieldDirective implements OnInit {
	private _container = inject(ViewContainerRef);

	readonly config = input(undefined);

	readonly group = input<FormGroup>(undefined);

	component;

	ngOnInit() {
		const component = components[this.config().type];
		this.component = this._container.createComponent(component);
		this.component.instance.config = this.config();
		this.component.instance.group = this.group();
	}
}
