import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss'],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        
        MatDialogContent,
        MatFormField,
        MatLabel,
        MatInput,
        FormsModule,
        ReactiveFormsModule,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ]
})
export class CommentsComponent implements OnInit {
	data = inject(MAT_DIALOG_DATA);
	private dialogRef = inject<MatDialogRef<CommentsComponent>>(MatDialogRef);

	commentControl: FormControl = new FormControl('');

	saveComments() {
		this.dialogRef.close(this.commentControl.value);
	}

	ngOnInit() {
		this.commentControl.patchValue(this.data);
	}
}
