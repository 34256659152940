import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogActions } from '@angular/material/dialog';
import { SharedService } from '../../../shared';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-pathology-edit',
    templateUrl: './pathology-edit.component.html',
    styleUrls: ['./pathology-edit.component.scss'],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ]
})
export class PathologyEditComponent implements OnInit {
	data = inject(MAT_DIALOG_DATA);
	private service = inject(SharedService);
	private fb = inject(FormBuilder);
	private dialogRef = inject<MatDialogRef<PathologyEditComponent>>(MatDialogRef);

	form: FormGroup;
	title = 'PATHOLOGY';
	headerIcon = 'dna';

	constructor() {
		this.form = this.fb.group({
			id: 0,
			value: ['', Validators.required],
		});
	}

	ngOnInit() {
		if (this.data && this.data.type === 'external') {
			this.title = this.data.title;
			this.headerIcon = this.data.icon;
		}
		this.form.patchValue(this.data || {});
	}

	saveData(data) {
		if (this.data && this.data.type === 'external')
			this.dialogRef.close(data);
		else
			this.service
				.savePathology(data)
				.subscribe(res => this.dialogRef.close(res));
	}
}
