import { Component, OnInit, inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogClose,
  MatDialogActions,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SharedService } from "../shared.service";
import {
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import moment from "moment";
import { SmsTemplateDTO } from "../../model";
import { TranslateModule } from "@ngx-translate/core";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatIconButton, MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatToolbar } from "@angular/material/toolbar";

@Component({
  selector: "ft-sms-sender",
  templateUrl: "./sms-sender.component.html",
  styleUrls: ["./sms-sender.component.scss"],
  imports: [
    MatToolbar,
    MatIcon,
    MatIconButton,
    MatDialogClose,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    MatDialogActions,
    MatButton,
    TranslateModule,
  ],
})
export class SmsSenderComponent implements OnInit {
  data = inject(MAT_DIALOG_DATA);
  private _dRef = inject<MatDialogRef<SmsSenderComponent>>(MatDialogRef);
  private _snack = inject(MatSnackBar);
  private _service = inject(SharedService);

  public destinationControl = new FormControl("", [Validators.minLength(9)]);
  public messageControl = new FormControl("", [Validators.required]);
  public templateControl = new FormControl("");
  public messageError: string;

  public smsTemplates: SmsTemplateDTO[] = [];

  ngOnInit(): void {
    this.destinationControl.patchValue(this.data.patientPhoneNumber);
    this._service.getSmsTemplates().subscribe((res) => {
      this.smsTemplates = res;
      const selectedModel = res.filter((it) => it.is_default)![0] || res[0];
      this.templateControl.patchValue(selectedModel?.title);
      this.buildModel(selectedModel);
    });
  }

  sendSms() {
    this._service
      .sendSms(
        this.data.id,
        this.destinationControl.value,
        this.messageControl.value,
        this.data.source,
      )
      .subscribe((res) => {
        if (res && res.error === "") {
          this.messageError = null;
          this._dRef.close(true);
        } else this.messageError = "Error: SMS non envoyé";
      });
  }

  onSelectTemplate(ev: any) {
    const template = this.smsTemplates.find((it) => it.title === ev.value);
    this.buildModel(template);
  }

  private buildModel(template: any) {
    const sms = template?.body
      .replace("PATIENT_NAME", this.data.patientName)
      .replace("EXAM", this.data.procedureCode)
      .replace(
        "DATE",
        moment(this.data.appointmentDateTime).format("DD/MM/YYYY HH:mm"),
      );
    this.messageControl.patchValue(sms);
  }
}
